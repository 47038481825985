import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProductsService {
  constructor(private http: HttpClient) {}

  loadProducts(productFilter: any): Observable<any> {
    const { page, ...filter } = productFilter;
    return this.http.get<any>(`${environment.primaryApiUrl}/product`, {
      params: {
        page,
        filter: JSON.stringify(filter),
        general: 0,
      },
    });
  }

  collectRecipes(checkedProductsIds: number[]): Observable<any> {
    return this.http.post<any>(
      `${environment.primaryApiUrl}/recipes/selection`,
      {
        product_ids: checkedProductsIds,
      },
    );
  }

  compareProducts(checkedProductsIds: number[]): Observable<any> {
    return this.http.post<any>(
      `${environment.primaryApiUrl}/products/comparison`,
      {
        data: {
          ids: checkedProductsIds,
        },
      },
    );
  }

  toggleProductToLocked(productsId: number): Observable<any> {
    return this.http.put<any>(
      `${environment.primaryApiUrl}/products/${productsId}/locked`,
      {},
    );
  }

  loadProductNutrientFilters(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/products/filters`);
  }

  loadProductGroups(): Observable<any> {
    return this.http.get<any>(`${environment.primaryApiUrl}/dict/category`);
  }
}
